import React from 'react'
import rec from '../data/AboutMe'
import { RecWrapper, RecBox, RecDes, RecRec } from '../styles/recStyle.js'
import Layout from '../components/layout'
import me from '../assets/images/me.jpg'

const AboutMe = () => (
  <Layout>
  <RecWrapper>
    <RecRec>
        <img src={me} height="500px" />
        
        <p><h1>Why Software Engineering?</h1> I love how programming tests the boundaries of our creativity. There is no limit as to how creative we can get with our solutions. As long as we can think of a logical idea, we can make it happen. It encourages me, challenges me and pushes me to my limits. <br/> <br/> I’m passionate about utilizing my creativity and coding skills to build software that focuses on improving the lives of those around me. Moreover, the tech industry is extremely demanding and is continuously evolving, which motivates me to constantly improve myself in order to keep up. </p>
    </RecRec>
      <h3>More about me!</h3>
        <RecDes>
          <i>“If our hopes of building a better and safer world are to become more than wishful thinking, we will need the engagement of volunteers more than ever.”</i> <b>— Kofi Annan</b>
          <br/><br/>A huge passion of mine is volunteering. When I was younger, we had a school trip to an orphanage, to volunteer and to give back to the community. Ever since then, I've been volunteering consistently. 
          <br/><br/>Back in Indonesia, I worked together with different programs from Habitat for Humanity and other non-profit organizations. All throughout high school, as part of the Student Council, we also often organized fundraising and voluntering events. 
          <br/><br/> During college, I had the opportunity to become the president of We Care Club, a community service club. We regularly held volunteering events throughout the year, mostly doing beach cleanups and helping out at food banks. 
          <br/><br/> Late last year, I was also fortunate enough to be able to intern at YCAB foundation, a non-profit organization that aims to improve the quality of life of youths and mothers through education and innovative financing.
          <br/><br/> Voluntering has always been a huge part of me growing up. It has taught me so much, from learning new skills and meeting new people to teaching me gratitude and empathy. 
          <br/><br/> Spending just a few hours of your day could make a big difference to the community. If you're interested in making a difference, both <a href="https://www.habitat.org/where-we-build/indonesia">Habitat for Humanity</a> and <a href="https://www.ycabfoundation.org/who-we-are/about-ycab-foundation/">YCAB Foundation</a>  are always open for donations and/or volunteers. 
          
          <br/><br/>  </RecDes>
        <hr/>
  </RecWrapper>
  </Layout>
)

export default AboutMe